<template>
  <div class="home">
    <section id="welcome_section">
      
      <div id="mask"></div>
      <video autoplay loop muted src="../assets/startseite_video.mp4"></video>

      <!-- <video autoplay loop src="../assets/TeaserVideo.mp4"></video> -->
      <!-- <div>
        <img src="../assets/Plantfit_Logo_weiß-17.png" alt="">
        <h1>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla perferendis tenetur, perspiciatis et eaque dolorem voluptate reprehenderit, consectetur eos dolore corrupti nemo natus! Neque quaerat sed delectus repellendus nobis officiis?</h1>
      </div> -->

    </section>

<!--     <section id="phone_section">
      <div>
        <img src="../assets/app_1.png">
        <img src="../assets/app_2.png">
      </div>

      <div id="app_download_links_container">
        <a target="_blank" href="https://apps.apple.com/pl/app/plantfitcoaching/id1620146645" class="app_download_link">
          <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Apple</title><path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701"/></svg>
          <p>Download im<br>App Store</p>
        </a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=digifit.android.virtuagym.pro.plantfitcoaching" class="app_download_link">
          <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Google Play</title><path d="M22.018 13.298l-3.919 2.218-3.515-3.493 3.543-3.521 3.891 2.202a1.49 1.49 0 0 1 0 2.594zM1.337.924a1.486 1.486 0 0 0-.112.568v21.017c0 .217.045.419.124.6l11.155-11.087L1.337.924zm12.207 10.065l3.258-3.238L3.45.195a1.466 1.466 0 0 0-.946-.179l11.04 10.973zm0 2.067l-11 10.933c.298.036.612-.016.906-.183l13.324-7.54-3.23-3.21z"/></svg>
          <p>Download im<br>Google Play Store</p>
        </a>
      </div>

    </section> -->

<!--     <section id="feedback_section">
      <div>
        <div class="feedback">
          <div class="feedback_header">
            <p>Nick</p>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>
          </div>
          <div class="feedback_body">
            <p>Es ist wirklich super einfach gehalten und es hat so viele Möglichkeite, einfach eine Klasse App!</p>
          </div>
        </div>
        <div class="feedback">
          <div class="feedback_header">
            <p>Sina</p>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>
          </div>
          <div class="feedback_body">
            <p>Trainings- und Ernährungspläne individuell auf mich zugeschnitten, gefällt mir sehr gut!</p>
          </div>
        </div>
        <div class="feedback">
          <div class="feedback_header">
            <p>Joachim</p>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
            </div>
          </div>
          <div class="feedback_body">
            <p>Die App bietet viele Tools, die das Training verbessern und das Monitoring erleichtern. Einfach zu bedienen, übersichtlich und gut durchdacht! Toll!</p>
          </div>
        </div>
      </div>
    </section> -->


    <section id="about_section">

      <div>
          <h1>Dein Plantfit Coach</h1>
          <p>Ich heiße <span class="text_highlight">Ariana Bibic</span> und bin dein <span class="text_highlight">veganer Fitness-und Ernährungscoach</span>. Seit nun fast 10 Jahren ernähre ich mich vegan und konnte mein Wissen mit Hilfe von Fortbildungen und im Studium vertiefen. Mir ist es wichtig, dich dabei zu unterstützen, dich in deinem Körper wohl zu fühlen und deineindividuellen Ziele zu erreichen. Egal ob <span class="text_highlight">abnehmen</span>, <span class="text_highlight">Muskelaufbau</span> oder <span class="text_highlight">Athletiktraining</span>, gemeinsam erstellen wir dir deinen <span class="text_highlight">Trainings-und Ernährungsplan auf pflanzlicher Basis</span>. Ich selbst trainiere mit dem Fokus auf Muskelaufbau und spezifischen Übungen zur Performancesteigerung im Bezug auf meine Leidenschaft zum Bmx Freestyle fahren. Aber auch die aktive Entspannung darf bei mir als <span class="text_highlight">Yogalehrerin</span> nicht zu kurz kommen.</p>
          <div id="timeline_container">
            <div id="timeline">
              <div class="timeline_point"></div>
              <div class="timeline_point"></div>
              <div class="timeline_point"></div>
              <div class="timeline_point"></div>
              <div class="timeline_point"></div>
              <div class="timeline_point"></div>
              <div class="timeline_point"></div>
            </div>
            <div>
              <p><span>2018</span> Vegane Sporternährung und Ernährungsplanerstellung</p>
              <p><span>2019</span> 300h Yoga Teacher Training in Indien, Rishikesh</p>
              <p><span>2020</span> Leistungssport Body Trainer</p>
              <p><span>2021</span> Fitness-und Athletiktrainer A-Lizenz </p>
              <p><span>2021</span> Vegane Ernährung für Mutter und Kind</p>
              <p><span>2018-2022</span> Bachelor of Arts "Gesundheitsmanagement"</p>
              <p><span>Bachelor Thesis</span> "Vegane Ernährung und Sport in der Schwangerschaft"</p>
            </div>
          </div>
          <div id="siegel_container">
            <img src="../assets/logo_dhfpg.jpg" alt="">
            <img src="../assets/ecodemy-siegel.png" alt="">
          </div>
        </div>
        <img id="about_me_img" src="../assets/aboutme_img.jpeg">
    </section>

<!--     <section id="book_time_section">
      <vg-guest-booking id="virtuagym_widget" widget-key="62e64ea4941782001a6bb7d6" club-id="65590" lang="de" source=""></vg-guest-booking>
    </section> -->

<!--     <section id="mitgliedschaften_section">
      <div class="mitgliedschaft">
          <div class="mitgliedschaft_header">
            <h1>plantFREE</h1>
            <img src="../assets/Logo_Mitgliedschaften_FREE-25.png" alt="">
            <h2>0,00€</h2>
          </div>
          <div class="mitgliedschaft_body">
            <p>
              Dies ist eine Testversion, damit du die allgemeinen Funktionen der App testen kannst. Es gibt Beispieltrainingspläne und Kurse und du kannst dein Essen tracken. Für ein individuelles Coaching werde plantPRO Mitglied oder vereinbare ein individuelles Beratungsgespräch.
            </p>
          </div>
          <div class="mitgliedschaft_footer">
            <a target="_blank" href="https://plantfitcoaching.virtuagym.com/webshop/product?id=12b1a0b9f8b9fa385663d5665860ce858249&club=TEM0eStRMGh2T0FWN2w1VEJxYnk3UT09&embedded=1">Zum Produkt</a>
          </div>
        </div>

        <div class="mitgliedschaft">
          <div class="mitgliedschaft_header">
            <h1>plantPRO</h1>
            <img src="../assets/Logo_Mitgliedschaften_Premium-27.png" alt="">
            <h2>299,00€</h2>
          </div>
          <div class="mitgliedschaft_body">
            <p>
              mit der plantPRO Mitgliedschaft hast du alles inklusive, um deine Ziele bestmöglich zu erreichen: 24/7 Erreichbarkeit deines/r Trainers/Trainerin, 2x/Monat 1:1 Coaching, individueller Trainingsplan und Ernährungsplan, Rezepte Blog, Community Bereich, Kurse
            </p>
          </div>
          <div class="mitgliedschaft_footer">
            <a target="_blank" href="https://plantfitcoaching.virtuagym.com/webshop/product?id=ba300610db13d870ad01977b4f0c27bdc701&club=TEM0eStRMGh2T0FWN2w1VEJxYnk3UT09">Zum Produkt</a>
          </div>
        </div>
    </section> -->

    <section id="magazin_section">
      <div>
        <h1>Das Plantfit Magazin</h1>
        <p>
          Wissenschaftliches Magazin für vegane Sportler/innen. Die erste Ausgabe beschäftigt sich mit dem Thema Veganismus für schwangere Sportler/innen. Dies ist die Printversion, sprich das Magazin wird dir per Post zugeschickt. Damit du aber sofort nach dem Kauf schonmal lesen kannst, bekommst du es zusätzlich digital per Mail zum downloaden. Vorteil: Veganes Rezepte Magazin inklusive!
        </p>
        <a target="_blank" href="https://plantfitcoaching.myshopify.com/products/plantfit-magazin-vegan-schwanger-und-sportlich-1" data-v-f61bf7bc="">Zum Magazin</a>
      </div>
      <div>
        <img src="../assets/magazin/Plantfit_Magazin_Cover.png" />
      </div>
    </section>
<!-- 
    <section id="magazine_section">
      <h1>Plantfit Magazin</h1>
      <div id='product-component-1681046263892 magazine_shopify'></div>
      <div>
        <Transition>
          <div id="magazine_info_container" v-show="!showMagazineBuyForm">
            <p>Die ersten Seiten des Plantfit Magazins jetzt Probelesen!</p>
            <button @click="this.showMagazineBuyForm = true" id="order_now_btn">Jetzt bestellen</button>
          </div>
        </Transition>
        <Transition>
          <div id="magazine_buy_container" v-show="showMagazineBuyForm">
            <p>Bitte füllen Sie das folgende Formular aus</p>
            <form action="">
              <div class="form_row">
                <input type="text" id="vorname_input" placeholder="Vorname">
                <input type="text" id="nachname_input" placeholder="Nachname">
              </div>
              <div class="form_row">
                <input type="text" id="strasse_input" placeholder="Straße / Hausnummer">
              </div>
              <div class="form_row">
                <input type="text" id="postleitzahl_input" placeholder="Postleitzahl">
                <input type="text" id="ort_input" placeholder="Ort">
              </div>
              <div class="form_row">
                <input type="text" id="mail_input" placeholder="E-Mail">
              </div>
              <div class="form_row">
                <input type="text" id="mail_repeat_input" placeholder="E-Mail wiederholen">
              </div>
              <div id="smart-button-container">
                <div style="text-align: center;">
                  <div id="paypal-button-container"></div>
                </div>
              </div>
            </form>
          </div>
        </Transition>
        <div>
          <div id="magazine">

          <div @click="this.$refs.page1.classList.toggle('flipped')" ref="page1" id="page1" class="page">
            <div class="front">
              <div id="f1"></div>
            </div>
            <div class="back">
              <div id="b1"></div>
            </div>
          </div>

          <div id="page2" class="page">
            <div class="front">
              <div id="f2"></div>
            </div>
            <div class="back">
              <div id="b2"></div>
            </div>
          </div>

          <div id="page3" class="page">
            <div class="front">
              <div id="f3"></div>
            </div>
            <div class="back">
              <div id="b3"></div>
            </div>
          </div>

          <div id="page4" class="page">
            <div class="front">
              <div id="f4"></div>
            </div>
            <div class="back">
              <div id="b4"></div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>

    <section id="faq_section">
      <h1>FAQ</h1>
    
    </section> -->

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>

<style scoped>

.text_highlight {
  color: white;
  font-weight: bold;
}

#welcome_section {
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-image: url(../assets/startseite_background.png);
  background-size: cover;
  position: relative;
}

#welcome_section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: .85;
}

#welcome_section > video {
  height: 100%;
  width: auto;
  z-index: 2;
}

#welcome_section > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 200px;
  z-index: 2;
  padding-top: 150px;
}

#welcome_section > div > img {
  width: 500px;
  height: auto
}

#welcome_section > div > h1 {
  margin: 0;
  text-align: center;
  color: white;
}

/* bewertung */
#feedback_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 50px 0;
}

#feedback_section h1 {
  font-size: 35px;
  margin-bottom: 5px;
  font-family: "Fatface";
}

#feedback_section > div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.feedback {
  width: 30%;
  height: 180px;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.feedback_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.feedback_header > div > svg {
  width: 20px;
  height: 20px;
  fill: #033E40;
  stroke: #033E40;
}

.feedback_body p {
  font-family: "Muli-Light";
}

@media (max-width: 1250px) {
  #feedback_section {
    padding: 25px 0;
  }
  .feedback {
    width: 32%;
  }
}

@media (max-width: 1115px) {
  #feedback_section > div {
    flex-direction: column;
  }

  .feedback {
    width: 95%;
  }
}

/* about me section */

#about_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 100%;
  background-color: #858585;
}

#about_section h1 {
  font-size: 35px;
  margin-bottom: 5px;
  color: white;
}

#about_section > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  height: 1050px;
}

#about_section > div:nth-of-type(1) {
  padding-left: 100px;
}

#about_section p {
  font-family: "Muli-Light";
  color: white;
  font-size: 20px;
}


#about_section ul {
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#about_section ul > li {
  font-family: "Muli-Light";
}

#about_section ul > li > span {
  font-weight: bold;
}

#about_me_img {
  height: 1050px;
  width: 100%;
  object-fit: cover;
}

#siegel_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

#siegel_container > img {
  border-radius: 10px;
  height: 180px;
  width: auto;
}

li::marker {
  color: #181818;
}

#timeline_container {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px 0 30px;
}

#timeline_container > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  padding-left: 30px;
}

#timeline_container > div:nth-of-type(2) > p {
  margin: 0;
  text-align: left;
  width: 100%;
  height: 25px;
}

#timeline_container > div:nth-of-type(2) > p > span {
  font-family: "Muli";
}

#timeline {
  height: 100%;
  width: 5px;
  border-radius: 10px;
  background-color: #033E40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.timeline_point {
  width: 20px;
  height: 20px;
  background-color: #033E40;
  border-radius: 50%;
}

#socials_row > a {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  text-decoration: none;
  gap: 10px;
  font-family: "Muli-Light";
  border: 2px solid #D7C7F8;
  padding: 10px;
  border-radius: 10px;
  width: 220px;
  transition: all .2s ease;
  color: #D7C7F8;
}

#socials_row > a:hover {
  background-color: #181818;
  color: white;
}

#socials_row > a > svg {
  stroke: #D7C7F8;
  transition: stroke .2s ease;
}

#socials_row > a:hover > svg {
  stroke: white;
}

@media (max-width: 1250px) {
  #about_section {
    flex-direction: column;
    padding: 0;
  }

  #about_section > div:nth-of-type(1) {
    padding-left: 30px;
  }

  #about_section > div {
    width: 100%;
    padding: 15px 30px;
    min-height: 0;
    height: 100%;
  }

  #about_me_img {
    height: 100%;
  }
}

@media (max-width: 765px) {

  #timeline_container > div:nth-of-type(2) > p {
    font-size: 16px;
  }
}

@media (max-width: 650px) {

  #about_section > div {
    padding-left: 0;
    padding: 10px 20px;
  }
}

@media (max-width: 600px) {

#siegel_container {
    flex-direction: column;
  }
}

/* @media (max-width: 595px) {

#timeline_container > div:nth-of-type(2) > p {
  font-size: 13px;
}
} */

/* phone section */
#phone_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
  position: relative;
}

#phone_section > h1 {
  font-size: 35px;
}

#phone_section > p {
  width: 50%;
  margin: 0 0 30px;
}

#phone_section > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px;
  align-items: center;
}

#phone_section > div > img {
  width: 50%;
  height: auto;
}

/* media queries */
@media (max-width: 1040px) {
  #phone_section > div:nth-of-type(1) {
    flex-direction: column;
  }
  #phone_section > div > img {
    width: 100%;
    height: auto;
  }
}

#app_download_links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px 20px;
  position: absolute;
  left: 160px;
  bottom: 0;
}

#app_download_links_container > a {
  background-color: #264d4b;
  padding: 15px 20px;
  width: 300px;
  height: 80px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#app_download_links_container > a > svg {
  width: 35%;
  height: 100%;
  fill: white;
  stroke: white;
}

#app_download_links_container > a > p {
  width: 65%;
}

@media (max-width: 1660px) {
  #app_download_links_container {
    left: 130px;
  }

  #app_download_links_container > a {
    padding: 15px 20px;
    width: 250px;
    height: 60px;
  }

  #app_download_links_container > a > svg {
    width: 20%;
  }

  #app_download_links_container > a > p {
    width: 80%;
  }
}

@media (max-width: 1350px) {
  #app_download_links_container {
    left: 100px;
    bottom: -15px;
  }

  #app_download_links_container > a {
    padding: 15px 20px;
    width: 200px;
    height: 60px;
    font-size: 13px;
  }
}

@media (max-width: 1040px) {
  #app_download_links_container {
    /* left: 0px;
    right: 0;
    bottom: 50%; */
    position: initial;
    background-color: white;
    width: 100%;
  }

  #app_download_links_container > a {
    padding: 15px 20px;
    width: 300px;
    height: 80px;
    font-size: 16px;
  }

  #app_download_links_container > a > svg {
    width: 35%;
  }

  #app_download_links_container > a > p {
    width: 65%;
  }
}

@media (max-width: 730px) {
  #app_download_links_container {
    flex-direction: column;
  }
}


#phone_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*#phone_container > div:nth-of-type(1), #phone_container > div:nth-of-type(3) {
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}*/

.app_feature {
  padding: 70px 0;
}

.app_feature_icon {
  border: 2px solid black;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_feature_icon > svg {
  width: 70%;
  height: 70%;
}

/* magazine section */
#magazine_section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
}

#magazine_section > h1 {
  font-size: 35px;
}

#magazine_section > p {
  width: 50%;
  margin: 0 0 30px;
}

#magazine_shopify {
  display: flex;
  flex-direction: row;
}

/*#magazine_section > div {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-top: 30px;
  gap: 10px;
}*/

#magazine_info_container {
  padding: 10px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
}

#magazine_info_container > p {
  font-family: "Muli-Light";
  padding: 10px;
}

#magazine_buy_container {
  width: 55%;
}

#magazine_buy_container > form input {
  width: 100%;
  padding: 10px;
  font-size: 17px;
  border-radius: 5px;
  border: 2px solid rgb(211, 211, 211);
}

#magazine_buy_container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#magazine_buy_container > p {
  text-align: center;
}

#magazine_buy_container > form button {
  background-color: black;
  padding: 5px 15px;
  width: 450px;
  height: 60px;
  border-radius: 5px;
  color: white;
  font-family: "Muli";
  font-size: 20px;
  border: none;
  cursor: pointer;
}

.form_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  width: 460px;
}

#order_now_btn {
  background-color: black;
  padding: 15px 20px;
  width: 300px;
  height: 80px;
  border-radius: 5px;
  color: white;
  font-family: "Muli";
  font-size: 20px;
  border: none;
  cursor: pointer;
}

#smart-button-container {
  width: 450px;
  z-index: 0;
}

#magazine {
  width: 460px;
  height: 650px;
  cursor: pointer;
  position: relative;
}

.page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  perspective: 1500px;
}

.front {
  z-index: 1;
  backface-visibility: hidden;
  /* border-left: 1px solid rgb(53, 53, 53); */
}

.front, .back {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: left;
  transition: transform .5s;
}

.front > div, .back > div {
  height: 100%;
  width: 100%;
}

.flipped .front, .flipped .back {
  transform: rotateY(-180deg);
}

.front {
  z-index: 1;
}

.back {
  z-index: 0;
}

#page1 {
  z-index: 3;
  transition: transform .2s;
}

.front:hover {
  transform: rotateY(-10deg);
}

.flipped > .front:hover {
  transform: rotateY(-180deg);
}

#page2 {
  z-index: 2;
}

#page3 {
  z-index: 1;
}

#page4 {
  z-index: 0;
}

#f1 {
  background-image: url(../assets/magazin/Plantfit_Magazin_Cover.png);
  background-position: center;
  background-size: cover;
}

#b1 {
  background-image: url(../assets/magazin/SEITE2.png);
  background-position: center;
  background-size: cover;
  transform: rotateY(-180deg);
}

#f2 {
  background-image: url(../assets/magazin/Seite3.png);
  background-position: center;
  background-size: cover;
}

#b2 {
  background-image: url(../assets/magazin/Seite4.png);
  background-position: center;
  background-size: cover;
  transform: rotateY(-180deg);
}

/* mitgliedschaften section */

#mitgliedschaften_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 20px 0;
  background-color: #033E40;
}


/* coaching section */


#coaching_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #033E40;
  padding: 60px 0;
}

#coaching_section > h1 {
  font-size: 35px;
  color: white;
}

#coaching_section > p {
  width: 50%;
  margin: 0 0 30px;
}

#coaching_section > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#mitgliedschaften_container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.mitgliedschaft {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 650px;
  width: 370px;
  border-radius: 10px;
  box-shadow: 0 10px 20px -3px rgb(102, 102, 102);
  background-color: white;
}

.mitgliedschaft_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mitgliedschaft_header > img {
  width: 170px;
  height: auto;
  padding: 15px;
  border-radius: 100%;
}

.mitgliedschaft_header > h2 {
  margin-bottom: 0;
}

.mitgliedschaft_body {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mitgliedschaft_body > p {
  font-family: "Muli-Light";
  text-align: center;
  padding: 0 15px;
  margin: 0;
}

.mitgliedschaft_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mitgliedschaft_footer > a {
  display: block;
  width: 90%;
  padding: 10px 20px;
  background-color: #033E40 !important;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: all .2s ease;
  border: 2px solid #033E40;
}

.mitgliedschaft_footer > a:hover {
  color: #033E40 !important;
  background-color: white !important;
}

#virtuagym_widget {
  width: 50% !important;
  padding-left: 50px;
}

#magazin_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;
  padding: 50px 350px;
}

#magazin_section > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#magazin_section h1 {
  font-size: 35px;
}

#magazin_section p {
  font-family: "Muli-Light";
}

#magazin_section a {
  text-decoration: none;
  background-color: #033e40;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-size: 16px;
}

#magazin_section img {
  height: 800px;
  width: auto;
}

@media (max-width: 930px) {

  #mitgliedschaften_section, #magazin_section {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;
      padding: 30px 50px;
    }
    
    #magazin_section > div:nth-of-type(1) {
      width: 100%;
    }
  .mitgliedschaft {
    width: 90%;
  }
}

@media (max-width: 930px) {
  #magazin_section img {
    width: 100%;
    height: auto;
  }
}

</style>
